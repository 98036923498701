import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';

const Node = styled.div`
  padding: 10px 20px;
  border-radius: 5px;
  background: ${(props) => props.theme.nodeBg};
  color: ${(props) => props.theme.nodeColor};
  border: 1px solid ${(props) => (props.selected ? props.theme.primary : props.theme.nodeBorder)};

  .react-flow__handle {
    background: ${(props) => props.theme.primary};
    width: 8px;
    height: 10px;
    border-radius: 3px;
  }
`;

export default memo(({ data, selected }) => {
    return (
        <Node selected={selected}>
            <Handle type="source" position={Position.Left} id="left"/>
            <div>
                <strong>{data.label}</strong>
            </div>
            <Handle type="source" position={Position.Right} id="right" />
        </Node>
    );
});
